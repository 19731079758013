.timeline-slider .slick-list .slick-track .slick-slide {
  padding: 10px;
  border-radius: 6px;
}

.timeline-slider-md .slick-list .slick-track .slick-slide {
  padding: 6px 4px;
}

.timeline-slider .slick-prev:before, .slick-next:before  {
  content: '' !important;
}

.timeline-slider .slick-prev {
  left: -4px;
  height: 150px;
  width: 35px;
  color: #fff;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.timeline-slider .slick-next {
  right: -4px;
  height: 150px;
  width: 35px;
  color: #fff;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.timeline-slider-md .slick-prev {
  left: -33px;
  height: 68px;
  background-color: initial;
}

.timeline-slider-md .slick-next {
  right: -37px;
  height: 68px;
  background-color: initial;
}

.timeline-slider .slick-prev svg {
  height: 100%;
  width: 150%;
  margin: 1px -10px;
}

.timeline-slider .slick-next svg {
  height: 100%;
  width: 150%;
  margin: 1px -10px;
}

.timeline-slider-md .slick-prev svg {
  width: 100%;
  margin: 2px 0;
}

.timeline-slider-md .slick-next svg {
  width: 100%;
  margin: 2px 0;
}

.timeline-slider .slick-next:hover {
  background-color: rgba(0, 0, 0, 0.4);
  /* background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)); */
}
.timeline-slider .slick-prev:hover {
  background-color: rgba(0, 0, 0, 0.4);
  /* background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)); */
}

.timeline-slider-md .slick-prev:hover {
  color: #000;
  background-color: inherit;
}

.timeline-slider-md .slick-next:hover {
  color: #000;
  background-color: inherit;
}


.timeline-slider .slick-track {
  display: flex;
}






.slider-card {
  /* width: 100%; */
  width: 266.67px;
  height: 150px;
  overflow: hidden;
  background-color: black;
  backface-visibility: hidden;
  cursor: pointer;
  display: block;
  position: relative;
  transform: scale(1, 1) translateZ(0px);
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: 4px;

  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;

  /* align center the smaller items in the slider box */
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-card-md {
  width: 120px;
  height: 67.50px;
}

.slider-card:hover:not(.slider-card-active) {
  transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;

  -moz-box-shadow: inset 0 0 5px 5px #888;
  -webkit-box-shadow: inset 0 0 5px 5px#888;
  box-shadow: inset 0 0 5px 5px #888;

  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

.slider-card:not(.slider-card-active)::after {
  bottom: 0px;
  content: "";
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 4px;
  border-width: 4px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0);
  border-image: initial;
  transition: border 300ms ease-out 0s;
}

.slider-card:hover:not(.slider-card-active)::after/*, .slider-card:focus::after*/ {
  border-width: 4px;
  border-style: solid;
  border-color: #1abc9c;
  border-image: initial;
}

.slider-card-active {
  transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
  -moz-box-shadow: inset 0 0 5px 5px #888;
  -webkit-box-shadow: inset 0 0 5px 5px#888;
  box-shadow: inset 0 0 5px 5px #888;
  border-width: 4px;
  border-style: solid;
  border-color: #1abc9c;
  border-image: initial;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
  /* transition: border 300ms ease-out 0s; */
}

.slider-card-image img {
  max-width: 100%;
  height: auto;
}
/*
.ab-slide-remove {
  position: absolute;
  top: 4px;
  right: 4px;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.4);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  z-index: 100;
}

.ab-slide-remove:hover {
  background-color: #d9534f;
} */
